import { TFunction } from 'i18next'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import { ISingleFilter } from 'src/ui-elements/list/ListContextProvider'

export const checkListStatusColorMapper: {
  [status in ChecklistTemplateStatus]: BadgeColor
} = {
  [ChecklistTemplateStatus.DRAFT]: BadgeColor.BLUE,
  [ChecklistTemplateStatus.ACTIVE]: BadgeColor.GREEN,
  [ChecklistTemplateStatus.ARCHIVED]: BadgeColor.DEFAULT,
}

export const checklistTemplateStatus = (
  t: TFunction<'translation'>,
): { id: string; name: string }[] => [
  {
    id: ChecklistTemplateStatus.DRAFT,
    name: t('draft'),
  },
  {
    id: ChecklistTemplateStatus.ACTIVE,
    name: t('active'),
  },
  {
    id: ChecklistTemplateStatus.ARCHIVED,
    name: t('archived'),
  },
]

export const checklistTemplateDomainFilter = (
  t: TFunction<'translation'>,
): ISingleFilter[] => [
  {
    value: ChecklistTemplateDomains.SYSTEM,
    name: t('system'),
    active: true,
  },
  {
    value: ChecklistTemplateDomains.FDVU,
    name: t('fdvu'),
    active: true,
  },
  {
    value: ChecklistTemplateDomains.RISK,
    name: t('risk'),
    active: true,
  },
  {
    value: ChecklistTemplateDomains.CONSTRUCTION,
    name: t('construction'),
    active: true,
  },
  {
    value: ChecklistTemplateDomains.PLANNING,
    name: t('planning'),
    active: true,
  },
  {
    value: ChecklistTemplateDomains.TASK,
    name: t('case'),
    active: true,
  },
  {
    value: ChecklistTemplateDomains.DOCUMENT,
    name: t('document'),
    active: true,
  },
]

export const enum ChecklistTemplateStatus {
  ACTIVE = 'active',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
}

export const enum ChecklistTemplateDomains {
  TASK = 'case',
  SYSTEM = 'system',
  RISK = 'risk',
  CONSTRUCTION = 'construction',
  PLANNING = 'planning',
  DOCUMENT = 'document',
  FDVU = 'fdvu',
}
