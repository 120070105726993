import { useQueryClient } from '@tanstack/react-query'
import { capitalize } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EnumPattern } from 'src/components/checklist/checklist-template/EnumPattern'
import useProjectId from 'src/components/hooks/useProjectId'
import CheckBox from 'src/components/switchHoc/CheckBox'
import FolderMetadataField from 'src/document/components/Folders/FolderMetadataField/FolderMetadataField'
import {
  metaDataTypes,
  BASE_METADATA_TYPES,
} from 'src/document/types/FolderMetadataTypes'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import useUserAccess from 'src/hooks/useUserAccess'
import { useChecklistTemplate } from 'src/query/checklist'
import {
  createCheckListItem,
  deleteChecklistItem,
  updateChecklistItemOrder,
  updateCheckListItem,
  createCheckListGroup,
  updateCheckListGroup,
  deleteChecklistGroup,
  updateChecklistGroupOrder,
  deleteMultipleChecklistItems,
  deleteMultipleChecklistGroups,
} from 'src/service/ChecklistService'
import { IChecklistGroup, IChecklistItem } from 'src/service/OrgTypes'
import ExpandableTable from 'src/systematic-completion/components/expandable-table/ExpandableTable'
import { useExpandableTable } from 'src/systematic-completion/components/expandable-table/hooks/useExpandableTable'
import { COLUMN_TYPES } from 'src/systematic-completion/components/expandable-table/types/COLUMN_TYPES'
import { ICellUpdate } from 'src/systematic-completion/components/expandable-table/types/ICellUpdate'
import { IExpandableSection } from 'src/systematic-completion/components/expandable-table/types/IExpandableSection'
import { IExpandableSectionConfig } from 'src/systematic-completion/components/expandable-table/types/IExpandableSectionConfig'
import { IHeader } from 'src/systematic-completion/components/expandable-table/types/IHeader'
import { IRow } from 'src/systematic-completion/components/expandable-table/types/IRow'

const ChecklistTemplateExpandableTable = ({
  checklistTemplateId,
}: {
  checklistTemplateId: number
}) => {
  const [rows, setRows] = useState<Record<string, IRow>>({})
  const { data: checklist } = useChecklistTemplate(checklistTemplateId)
  const [activeDataType, setActiveDataType] = useState<BASE_METADATA_TYPES>()
  const projectId = useProjectId()
  const queryClient = useQueryClient()
  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['checklistTemplate'] })
  }
  const { t } = useTranslation()

  const { isProjectAdmin } = useUserAccess()
  const writeAccess = isProjectAdmin

  useEffect(() => {
    if (!checklist) return
    createTable(
      checklist.checklist_groups ?? [],
      checklist.checklist_groups
        ? checklist.checklist_groups.flatMap(
            (group) => group.checklist_items ?? [],
          )
        : [],
    )
  }, [checklist])

  const [expandableSections, setExpandableSections] = useState<
    IExpandableSection[]
  >([])

  const [expandableSectionsConfig, setExpandableSectionsConfig] = useState<
    Record<string, IExpandableSectionConfig>
  >({})

  const onItemUpdated = ({ header, rowId, newValue }: ICellUpdate) => {
    return updateCheckListItem(+rowId, { [header.field]: `${newValue}` })
  }

  const addItem = async (
    sectionId: IExpandableSection['id'],
    newRow: IChecklistItem,
  ) => {
    const response = await createCheckListItem(projectId, {
      ...newRow,
      checklist_group_id: sectionId,
      project_id: projectId,
    })
    return response.id
  }

  const addGroup = async (sectionTitle: string) => {
    const response = await createCheckListGroup(projectId, {
      checklist_template_id: checklistTemplateId,
      name: sectionTitle,
    })
    return response.id
  }

  const updateGroup = async (sectionId: number, newTitle: string) => {
    await updateCheckListGroup(sectionId, 'name', newTitle)
    reload()
  }

  const PatternCell = (
    row: IChecklistItem,
    pattern: string[],
    rowId: number,
  ) => {
    switch (row?.data_type) {
      case 'multi_enum':
      case 'enum':
        return (
          <EnumPattern
            pattern={pattern}
            onChange={async (pattern) =>
              await updateCheckListItem(rowId, { pattern })
            }
          />
        )
      case 'boolean':
        return (
          <div className={'items-center'}>
            <CheckBox onChange={() => {}} disable />
          </div>
        )
      default:
        return (
          <div className={'italic text-gray-500'}>{pattern?.[0] ?? '-'}</div>
        )
    }
  }

  const headers = useMemo(
    (): IHeader[] => [
      {
        id: 'name',
        label: capitalize(t('name')),
        field: 'name',
        initialWidth: 400,
        minWidth: 400,
        type: COLUMN_TYPES.TEXT,
        draggable: false,
      },
      {
        id: 'data_type',
        label: capitalize(t('data_type')),
        field: 'data_type',
        initialWidth: 200,
        minWidth: 200,
        type: COLUMN_TYPES.CUSTOM,
        draggable: false,
        cell: ({
          value: data_type,
          rowId,
        }: {
          value: BASE_METADATA_TYPES
          rowId: number
        }) => {
          return (
            <div className={'w-full h-full content-center'}>
              <FolderMetadataField
                noBorder
                possibleMetadataTypes={metaDataTypes}
                disabled={!writeAccess}
                onTypeChange={async (newMetadataType) => {
                  await updateCheckListItem(rowId, newMetadataType)
                  setActiveDataType(newMetadataType?.data_type)
                  reload()
                }}
                value={{
                  data_type,
                  pattern: [],
                }}
              />
            </div>
          )
        },
      },
      {
        id: 'pattern',
        label: capitalize(t('options')),
        field: 'pattern',
        initialWidth: 400,
        minWidth: 400,
        type: COLUMN_TYPES.CUSTOM,
        draggable: false,
        cell: ({
          value: pattern,
          rowId,
          row,
        }: {
          value: string[]
          rowId: number
          row: IChecklistItem
        }) => (
          <div className={'p-1 h-full w-full content-center'}>
            {PatternCell(row, pattern, rowId)}
          </div>
        ),
      },
    ],
    [t],
  )

  const createTable = (
    checklistGroups: IChecklistGroup[],
    checkListItems: IChecklistItem[],
  ) => {
    const expandableSectionsConfigTmp: Record<
      string,
      IExpandableSectionConfig
    > = {}

    checklistGroups.map((group) => {
      expandableSectionsConfigTmp[group.id] = {
        id: group.id,
        isExpanded: true,
        rows: checkListItems
          .filter((item) => item.checklist_group_id === group.id)
          .map((item) => item.id!)
          .filter(Boolean),
        title: group.name,
      }
    })

    const expandableSectionsTmp = checklistGroups.map((e) => {
      return { id: e.id }
    })

    setRows(
      checkListItems.reduce((acc, checkListItem) => {
        acc[checkListItem.id] = checkListItem
        return acc
      }, {}),
    )
    if (!activeDataType)
      setActiveDataType(
        checkListItems[checkListItems.length - 1]?.data_type ??
          BASE_METADATA_TYPES.string,
      )
    setExpandableSectionsConfig(expandableSectionsConfigTmp)
    setExpandableSections(expandableSectionsTmp)
  }

  const updateOrder = async (
    row: IRow,
    _oldIndex: number,
    newIndex: number,
    _oldGroup: IExpandableSection['id'],
    newGroup: IExpandableSection['id'],
  ) => {
    if (row.hasOwnProperty('isExpanded')) {
      return updateChecklistGroupOrder(row.id, newIndex + 1)
    } else {
      return updateChecklistItemOrder(row.id, newIndex + 1, newGroup)
    }
  }

  const { confirmDelete } = useDeleteModal()
  const handleBulkDelete = async (rowIds: number[], sectionIds: number[]) => {
    const confirm = await confirmDelete({
      customTitle: t('are_you_sure_you_want_to_delete_with_param', {
        item:
          rowIds.length > 1 || sectionIds.length > 0
            ? `${rowIds.length + sectionIds.length} ${t('elements')}`
            : `${t('an_element')}`,
      }),
      itemName: `${rowIds.length + sectionIds.length} ${t('elements')}`,
    })
    if (confirm) {
      if (rowIds && rowIds.length) {
        await deleteMultipleChecklistItems(projectId, {
          checklist_template_id: checklistTemplateId,
          checklist_item_ids: rowIds,
        })
      }
      if (sectionIds && sectionIds.length) {
        await deleteMultipleChecklistGroups(projectId, {
          checklist_group_ids: sectionIds,
          checklist_template_id: checklistTemplateId,
        })
      }
      reload()
      return true
    }
    return false
  }

  const { store, interactionStore } = useExpandableTable(`checklist-table`, {
    onCellUpdated: onItemUpdated,
    createRow: addItem,
    deleteRow: (rowId) => deleteChecklistItem(+rowId),
    createSection: addGroup,
    deleteSection: deleteChecklistGroup,
    onSectionTitleUpdate: updateGroup,
    onReorderRows: updateOrder,
    partialStore: {
      headers,
      rows,
      expandableSections,
      expandableSectionsConfig,
      readonly: !writeAccess,
      itemTitle: 'checklist_item',
      sectionTitle: 'checklist_grouping',
      addSectionTitle: 'add_grouping',
      randomNumber: false,
      bulkDelete: true,
      isRowSelectable: writeAccess,
    },
  })

  return (
    <div className="p-2">
      <ExpandableTable
        store={store}
        interactionStore={interactionStore}
        canReorderRows
        projectId={projectId}
        readonly={!writeAccess}
        reload={reload}
        onBulkDelete={handleBulkDelete}
        initialItem={{
          data_type: activeDataType,
          pattern:
            activeDataType === BASE_METADATA_TYPES.time
              ? 'DD.MM.YYYY'
              : undefined,
        }}
      />
    </div>
  )
}

export default ChecklistTemplateExpandableTable
