import { useFlag } from '@unleash/proxy-client-react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import TagList from 'src/components/Tag/TagList'
import ChecklistTree from 'src/components/checklist/checklist-template/ChecklistTemplateTree'
import { ChecklistTemplateDomains } from 'src/components/checklist/utils'
import useProjectId from 'src/components/hooks/useProjectId'
import PhasesList from 'src/components/phase/PhasesList'
import PPUGoals from 'src/components/ppu-goals/PPUGoals'
import MainProcessList from 'src/components/process/main-process/MainProcessList'
import SystemTypeFieldsList from 'src/components/system/system-type-fields/SystemTypeFieldsList'
import SystemTypeGroupList from 'src/components/system/system-type-group/SystemTypeGroupList'
import TabView, { ITabs } from 'src/components/tab-view/TabView'

interface IPlanningSettingsPage {
  parentType: string
}

const PlanningSettingsPage: FC<IPlanningSettingsPage> = ({ parentType }) => {
  const { t } = useTranslation()
  const projectId = useProjectId()
  const enableChecklists = useFlag('checklists')

  const tabs: ITabs = {
    phases: {
      title: t('phases'),
      show: true,
      content: <PhasesList projectId={projectId} />,
    },
    main_processes: {
      title: t('main_processes'),
      show: true,
      content: <MainProcessList projectId={projectId} />,
    },
    types: {
      title: t('types'),
      show: true,
      content: <TagList projectId={projectId} />,
    },
    reliability_ppu_goals: {
      title: t('reliability_ppu_goals'),
      show: true,
      content: <PPUGoals />,
    },
    user_defined_fields: {
      title: t('user_defined_fields'),
      show: true,
      content: (
        <SystemTypeFieldsList projectId={projectId} domain={parentType} />
      ),
    },
    user_defined_field_groups: {
      title: t('user_defined_field_groups'),
      show: true,
      content: (
        <SystemTypeGroupList
          projectId={projectId}
          domain={parentType}
          subDomain={parentType}
          canCreatingMultiple={false}
        />
      ),
    },
    checklist_templates: {
      title: t('checklist_templates'),
      show: enableChecklists,
      content: <ChecklistTree domain={ChecklistTemplateDomains.PLANNING} />,
    },
  }

  return <TabView level={2} tabs={tabs} />
}

export default PlanningSettingsPage
