import Add from '@icons/add.svg'
import { omitBy } from 'lodash'
import moment from 'moment'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ExportTemplate } from 'src/context/ExportContext/ExportTypes'
import { actionListExportTemplate } from 'src/export-templates/ActionExports'
import { actionImportTemplate } from 'src/export-templates/ActionImportTemplate'
import useBoolean from 'src/hooks/useBoolean'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import TestSendInvitation from 'src/page/systematic-completion/tests/TestSendInvitation'
import { queryClient } from 'src/query/client'
import { useFilteredTaskItems } from 'src/query/tasks/filteredTasks'
import {
  IImportItemList,
  ITask,
  ITaskFormData,
  ITaskType,
  IUserData,
} from 'src/service/OrgTypes'
import { deleteBulkTasks, deleteTask } from 'src/service/TaskService'
import { sendInviteToTestParticipant } from 'src/service/TestService'
import Table from 'src/ui-elements/Table/Table'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import useAlert from 'src/ui-elements/toast/useAlert'
import { getDetailUrl } from 'src/utility/DetailPageUtils'
import {
  addMetaDataInfo,
  addUserDefinedInfoToImport,
} from 'src/utility/exportUtils'
import { statusPieShowTypes } from '../issue-kpi/ResolvedOverDueGraphs'
import { initializeMetaValues } from '../system/SystemUtil'
import CreateTaskForm from './CreateTaskForm'
import TaskInspectorPanel from './TaskInspectorPanel'
import { useTaskColumns } from './useTasksColumns'
import { copyTask } from './utils'

interface ActionTableProps {
  projectId: number
  leaderId?: number
  parentType?: string
  parentId?: number
  parentIds?: number[]
  lastAllowedDeadline?: moment.Moment
  customDeadlineErrorMessage?: string
  inModal?: boolean
  parentDone?: boolean
  isExpandedElement?: boolean
  tableName?: string
  improvementId?: number
  updateParentList?: () => void
  importActions?: boolean
  taskTypeTree?: boolean
  taskType?: ITaskType
  taskTypeStatFilters?: {
    date: moment.Moment[]
    discipline: number[]
    show: statusPieShowTypes
    task_type: number[]
  }
  onOpenItem?: (taskId: number, type: string, taskTypeId?: number) => void
  reloadTree?: () => void
  module?: 'planning'
  defaultTask?: Partial<ITaskFormData>
}

const ActionsList: FC<ActionTableProps> = ({
  tableName,
  taskTypeTree,
  parentDone,
  onOpenItem,
  projectId,
  parentId,
  parentType,
  taskType,
  defaultTask,
  reloadTree,
  importActions,
  lastAllowedDeadline,
  customDeadlineErrorMessage,
  module,
  parentIds,
  leaderId,
  improvementId,
  taskTypeStatFilters,
}) => {
  const tempTask = {
    parent_id: parentId,
    parent_type: parentType,
    task_type_id: taskType?.id,
    optional_fields: taskType?.optional_fields
      ? initializeMetaValues(taskType.optional_fields, 'Task')
      : undefined,
    ...defaultTask,
  }
  const [selectedTask, setSelectedTask] = useState<ITask>()
  const [copiedTask, setCopiedTask] = useState<Partial<ITaskFormData>>(tempTask)
  const [selectedTasks, setSelectedTasks] = useState<ITask[]>([])

  const reload = () => {
    queryClient.invalidateQueries({
      queryKey: ['tasks'],
    })
    reloadTree?.()
  }

  const { columns, legacyColumns, defaultOrdering } = useTaskColumns({
    options: {
      taskTypeTree,
      parentType,
      module,
      metaData: taskType?.optional_fields,
    },
    reload,
  })

  const { confirmDelete } = useDeleteModal()
  const { addAlert } = useAlert()
  const { setTrue, value, setFalse } = useBoolean([
    'inspect',
    'copy',
    'new',
    'suggestion',
    'reminder',
  ])

  const { t } = useTranslation()
  const params = useParams()

  const onRowClick = (row: ITask) => {
    if (onOpenItem && (row.task_type_id || !taskTypeTree)) {
      onOpenItem(row.id, 'task', row.task_type_id ?? undefined)
    } else {
      setTrue('inspect')
      setSelectedTask(row)
    }
  }

  const onCloneItem = async (row: ITask) => {
    const newTask = await copyTask(projectId, row)
    setCopiedTask(newTask)
    setTrue('copy')
  }

  const handlePreviewClick = (data: ITask) => {
    setSelectedTask(data)
    setTrue('inspect')
  }

  const onDeleteClick = async (task: ITask) => {
    const confirmed = await confirmDelete({
      itemName: task.title,
      itemIdnType: 'task',
    })
    if (!confirmed) return
    await deleteTask(task)
    reload()
  }

  const importActionTemplate: IImportItemList = {
    title: t('upload_actions'),
    templateJson: addUserDefinedInfoToImport(
      actionImportTemplate,
      [],
      taskType?.optional_fields ?? [],
    ),
    type: 'tasks',
    reload,
  }

  const deleteSelectedTasks = async (selectedTasks: number[]) => {
    await deleteBulkTasks(projectId, selectedTasks)
    addAlert({
      type: 'success',
      title: t('successfully_deleted'),
      description: t('selected_action_is_deleted'),
      autoClose: true,
    })
    reload()
    reloadTree?.()
  }

  const initFilters = () => {
    const activeFilters: TableFilter = {}
    Object.assign(activeFilters, {
      parent_id: parentIds || (parentId ? [parentId] : undefined),
      parent_type: parentType ? [parentType] : undefined,
      task_type: taskType
        ? [taskType.id]
        : tableName === 'TaskTypesDetailPageTable'
          ? ['null']
          : undefined,
      suggested:
        leaderId && value('suggestion') ? value('suggestion') : undefined,
      improvement: improvementId ? [improvementId] : undefined,
    })

    if (parentType === 'Delivery') {
      Object.assign(activeFilters, {
        parent_type: undefined,
        parent_id: undefined,
        delivery: [parentId],
      })
    }

    const typeMappings: Record<string, string[]> = {
      Meeting: ['Topic'],
      ControlArea: [
        'ChecklistParameter',
        'CheckInParameter',
        'ConstructionLocomotive',
      ],
    }

    Object.entries(typeMappings).forEach(([key, values]) => {
      if ((activeFilters.parent_type as string[])?.includes(key))
        (activeFilters.parent_type as string[]).push(...values)
    })

    if (
      module === 'planning' &&
      !(activeFilters.parent_type as string[])?.some((t) =>
        ['Delivery', 'Project', 'Topic', 'KeyPoint'].includes(t),
      )
    ) {
      activeFilters.parent_type = [
        ...((activeFilters.parent_type as string[]) || []),
        'Delivery',
        'Project',
        'Topic',
        'KeyPoint',
      ]
    }

    if (taskTypeStatFilters) {
      Object.assign(activeFilters, {
        discipline: [...taskTypeStatFilters.discipline],
        created_at: [
          moment(taskTypeStatFilters.date[0]).utc(),
          moment(taskTypeStatFilters.date[1]).utc(),
        ],
        status: ['overdue', 'open'].includes(taskTypeStatFilters.show ?? '')
          ? ['open', 'in_progress']
          : taskTypeStatFilters.show === 'resolved'
            ? ['done']
            : undefined,
        deadline:
          taskTypeStatFilters.show === 'overdue'
            ? [new Date(0), moment().subtract(1, 'day').utc()]
            : taskTypeStatFilters.show === 'open'
              ? [moment(), new Date(Math.max())]
              : undefined,
        task_type:
          taskTypeStatFilters.task_type[0] === -1
            ? ['null']
            : activeFilters.task_type,
      })
    }
    return omitBy(activeFilters, (v) => !v)
  }

  const getRedirectUrl = (row: ITask) => {
    if (module === 'planning') {
      return getDetailUrl.task({ taskId: row.id, params: params })
    }
    return row.task_type_id
      ? getDetailUrl.taskUnderCaseType({
          taskId: row.id,
          taskTypeId: row.task_type_id,
        })
      : getDetailUrl.taskWithoutCaseType({ taskId: row.id })
  }

  return (
    <>
      <Table
        generateRedirectUrl={getRedirectUrl}
        name={tableName ?? 'actionList'}
        columns={columns}
        defaultOrdering={defaultOrdering}
        legacyColumns={legacyColumns}
        useDataQuery={useFilteredTaskItems}
        onCopyClick={onCloneItem}
        onRowClick={onRowClick}
        onPreviewClick={handlePreviewClick}
        initialFilter={initFilters()}
        onDeleteClick={onDeleteClick}
        tableButtons={(selectedTasks) => ({
          customButtons: !parentDone
            ? [
                <Button
                  key="new"
                  type={Button.ButtonType.PRIMARY}
                  size={Button.ButtonSize.SMALL}
                  onClick={() => setTrue('new')}
                >
                  <Add className={'text-xl'} />
                  <span className="capitalize">{t('new_case')}</span>
                </Button>,
                <Button
                  key="send_reminder"
                  onClick={() => {
                    setSelectedTasks(selectedTasks)
                    setTrue('reminder')
                  }}
                  type={Button.ButtonType.BOUNDARY_WHITE}
                  disabled={selectedTasks.length === 0}
                >
                  {t('send_reminder')}
                </Button>,
              ]
            : [],
          exportData: addMetaDataInfo(
            actionListExportTemplate() as ExportTemplate,
            taskType?.optional_fields ?? [],
          ),
          importItem: importActions ? importActionTemplate : undefined,
          onBulkDelete: deleteSelectedTasks,
        })}
      />
      {value('new') && (
        <CreateTaskForm
          closeModal={() => {
            reload()
            setFalse('new')
          }}
          defaultTask={tempTask}
          lastAllowedDeadline={lastAllowedDeadline}
          deadlineErrorMessage={customDeadlineErrorMessage}
        />
      )}
      {value('copy') && (
        <CreateTaskForm
          closeModal={() => {
            reload()
            setFalse('copy')
          }}
          defaultTask={copiedTask}
          lastAllowedDeadline={lastAllowedDeadline}
          deadlineErrorMessage={customDeadlineErrorMessage}
        />
      )}
      {value('inspect') && selectedTask && (
        <TaskInspectorPanel
          taskId={selectedTask.id}
          open={value('inspect')}
          onClose={() => setFalse('inspect')}
          projectId={projectId}
          onUpdate={reload}
          module={module}
        />
      )}
      <TestSendInvitation
        show={value('reminder')}
        closeModal={() => {
          setFalse('reminder')
        }}
        itemId={selectedTasks.map((each) => each.id)}
        api={sendInviteToTestParticipant}
        numberOfUsers={selectedTasks.length}
        defaultInvitationSubject="Send Reminders to responsible people"
        title="send_reminder"
        participants={
          (selectedTasks
            ?.map((each) => each.responsible)
            .filter((res) => res !== undefined) ?? []) as IUserData[]
        }
        dataType={'Task'}
      />
    </>
  )
}

export default ActionsList
