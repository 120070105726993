import Add from '@icons/add.svg'
import DoNotDisturbOn from '@icons/do_not_disturb_on.svg'
import { useQueryClient } from '@tanstack/react-query'
import { ColumnFiltersState } from '@tanstack/react-table'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import KeypointInspectorPanel from 'src/components/key-point/KeyPointInspectorPanel'
import KeypointModal from 'src/components/key-point/KeypointModal'
import SelectKeypointModal from 'src/components/key-point/SelectKeypointModal'
import { useKeypointColumns } from 'src/components/key-point/useKeypointColumns'
import useUserAccess from 'src/hooks/useUserAccess'
import { useFilteredKeypointsWithPagination } from 'src/query/planning/keypoints'
import { IKeypoint } from 'src/service/OrgTypes'
import { IModalOrigin } from 'src/service/ProcessService'
import Table from 'src/ui-elements/Table/Table'
import Button from 'src/ui-elements/button/Button'
import { getDetailUrl } from 'src/utility/DetailPageUtils'

interface IRelatedKeypointsList {
  parentFilter: Record<string, number[]>
  defaultFilter?: ColumnFiltersState
  readonly?: boolean
  origin: IModalOrigin
  onOpenItem?: (id: number, type: string, parentId?: number) => void
  tableName: string
  addKeypoints?: (keypointIds: number[]) => Promise<void>
  removeKeypoints?: (keypointIds: number[]) => Promise<void>
  inInspectorPanel?: boolean
  reloadParent?: () => void
  generateRedirectUrl?: (keypoint: IKeypoint) => string
}

const RelatedKeypointsList = ({
  parentFilter,
  tableName = 'inspectorPanelKeypointsList',
  readonly,
  origin,
  onOpenItem,
  addKeypoints,
  removeKeypoints,
  inInspectorPanel = true,
  reloadParent,
  generateRedirectUrl,
}: IRelatedKeypointsList) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [selectedKeypointId, setSelectedKeypointId] = useState<number>()
  const [showCreateNewKeyPointModal, setShowCreateNewKeyPointModal] =
    useState(false)
  const [showAddKeypointModal, setShowAddKeypointModal] =
    useState<boolean>(false)
  const { writeAccess } = useUserAccess('planning')

  const reload = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['keypointsFilteredWithPagination'],
    })
    reloadParent?.()
  }, [queryClient])

  const { columns, inspectorPanelColumnVisibility } = useKeypointColumns({
    disabled: readonly || !writeAccess,
    reload,
  })

  return (
    <>
      <Table
        name={tableName}
        initialColumnVisibility={
          inInspectorPanel ? inspectorPanelColumnVisibility : undefined
        }
        initialFilter={parentFilter}
        columns={columns}
        useDataQuery={useFilteredKeypointsWithPagination}
        deleteIcon={DoNotDisturbOn}
        generateRedirectUrl={(row) =>
          generateRedirectUrl
            ? generateRedirectUrl(row)
            : getDetailUrl.keypoint({ keypointId: row.id })
        }
        onRowClick={(keypoint) => onOpenItem?.(keypoint.id, 'keypoint')}
        onDeleteClick={
          removeKeypoints && !readonly
            ? async (row) => {
                await removeKeypoints([row.id])
                reload()
              }
            : undefined
        }
        tableButtons={(selectedKeypoints) => ({
          customButtons: !readonly
            ? [
                <Button
                  key={'create-keypoint'}
                  type={Button.ButtonType.PRIMARY}
                  size={Button.ButtonSize.SMALL}
                  onClick={() => setShowCreateNewKeyPointModal(true)}
                  className="min-h-8"
                >
                  <Add className={'text-xl'} />
                  {t('new_keypoint')}
                </Button>,
                ...(addKeypoints
                  ? [
                      <Button
                        key={'add-keypoint'}
                        type={Button.ButtonType.SECONDARY}
                        size={Button.ButtonSize.SMALL}
                        onClick={() => setShowAddKeypointModal(true)}
                        className="min-h-8"
                      >
                        {t('add_keypoint')}
                      </Button>,
                    ]
                  : []),
                ...(removeKeypoints
                  ? [
                      <Button
                        key="remove_fike"
                        title={t('remove_documents')}
                        type={Button.ButtonType.DANGER}
                        className={'p-1 w-8 h-8'}
                        disabled={selectedKeypoints.length < 1}
                        onClick={async () => {
                          await removeKeypoints(
                            selectedKeypoints.map((system) => system.id),
                          )
                          reload()
                        }}
                      >
                        <DoNotDisturbOn
                          className={twMerge(
                            'text-xl',
                            selectedKeypoints.length > 0 && 'fill-red',
                          )}
                        />
                      </Button>,
                    ]
                  : []),
              ]
            : [],
        })}
        onPreviewClick={(keypoint) => setSelectedKeypointId(+keypoint.id)}
      />
      {selectedKeypointId && (
        <KeypointInspectorPanel
          keyPointId={selectedKeypointId}
          open={true}
          onClose={() => setSelectedKeypointId(undefined)}
          origin={origin}
          onUpdate={reload}
        />
      )}
      <KeypointModal
        improvementId={parentFilter['improvement']?.[0]}
        show={showCreateNewKeyPointModal}
        createKeypoint
        close={() => setShowCreateNewKeyPointModal(false)}
        keypoint={
          parentFilter?.['mile_stone']?.length === 1
            ? ({ mile_stone_id: parentFilter['mile_stone'][0] } as IKeypoint)
            : ({} as IKeypoint)
        }
        onCreateKeypoint={async (keypoint) => {
          addKeypoints && (await addKeypoints([keypoint.id]))
          reload()
        }}
      />
      {showAddKeypointModal && addKeypoints && (
        <SelectKeypointModal
          origin={origin}
          reload={reload}
          addKeypoints={addKeypoints}
          closeModal={() => setShowAddKeypointModal(false)}
          filter={Object.fromEntries(
            Object.entries(parentFilter).map(([key, value]) => [
              `not_${key}`,
              value,
            ]),
          )}
        />
      )}
    </>
  )
}

export default RelatedKeypointsList
