import { useQueryClient } from '@tanstack/react-query'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import ChecklistTemplateExpandableTable from 'src/components/checklist/checklist-template/ChecklistTemplateExpandableTable'
import {
  checklistTemplateStatus,
  checkListStatusColorMapper,
} from 'src/components/checklist/utils'
import useProjectId from 'src/components/hooks/useProjectId'
import useTree from 'src/hooks/useTree'
import useUserAccess from 'src/hooks/useUserAccess'
import { useChecklistTemplate } from 'src/query/checklist'
import {
  getChecklistTemplatesTree,
  updateCheckListTemplate,
} from 'src/service/ChecklistService'
import { IChecklistTemplate } from 'src/service/OrgTypes'
import Badge from 'src/ui-elements/badge/Badge'
import FieldGroup from 'src/ui-elements/fields/FieldGroup'
import PageHeader from 'src/ui-elements/page-display/PageHeader'
import PageRoot from 'src/ui-elements/page-display/PageRoot'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'

const ChecklistTemplateDetailPage = ({
  checklistTemplateId,
}: {
  checklistTemplateId: number
}) => {
  const { data } = useChecklistTemplate(checklistTemplateId)
  const queryClient = useQueryClient()
  const { reloadTree } = useTree(
    ['checklist_templates'],
    getChecklistTemplatesTree,
    'ChecklistTemplate',
  )

  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['checklistTemplate'] })
  }
  const { t } = useTranslation()
  const { isProjectAdmin } = useUserAccess()
  const writeAccess = isProjectAdmin
  const projectId = useProjectId()

  const onUpdate = async (update: Partial<IChecklistTemplate>) => {
    await updateCheckListTemplate(projectId, checklistTemplateId, {
      ...update,
      id: checklistTemplateId,
    })
    reload()
  }

  return (
    <PageRoot>
      <PageHeader
        title={data?.name}
        subTitle={''}
        additionalButtons={
          data?.status ? (
            <Badge
              text={t(data.status)}
              color={checkListStatusColorMapper[data.status]}
            />
          ) : undefined
        }
      />
      <div className={'flex'}>
        <InlineComponentsWrapper padding="left" border={'right'}>
          <TextInlineInputCompontent
            label="name"
            value={data?.name}
            validate={(newValue) => {
              if (!newValue?.length) {
                return t('fill_out_w_param', {
                  param: t('name'),
                })
              }
              return undefined
            }}
            onValueSubmitted={async (value) => {
              await onUpdate({ name: value })
              reload()
              reloadTree()
            }}
            disabled={!writeAccess}
          />
          <SelectorInlineInputComponent
            items={checklistTemplateStatus(t)}
            getItemLabel={(status) => status?.name ?? ''}
            label={'status'}
            onValueSubmitted={(status) => {
              onUpdate({ status: status })
            }}
            selectedId={data?.status}
            initialItem={{
              id: data?.status,
              name: capitalize(t(data?.status ?? 'draft')),
            }}
            cancelButton={true}
            inspectorPanel={false}
            disabled={!writeAccess}
          />
          <TextInlineInputCompontent
            label="source"
            value={data?.source}
            onValueSubmitted={(value) => {
              onUpdate({ source: value })
            }}
            disabled={!writeAccess}
          />
          <TextInlineInputCompontent
            label="domain"
            value={data?.domain ? capitalize(t(data?.domain)) : ''}
            disabled={true}
          />
          <TextInlineInputCompontent
            textArea
            label="description"
            value={data?.description}
            onValueSubmitted={(value) => {
              onUpdate({ description: value })
            }}
            disabled={!writeAccess}
          />
        </InlineComponentsWrapper>
        <InlineComponentsWrapper padding="left">
          <FieldGroup>
            <DateTimeInlineInputComponent
              selectedTime={data?.created_at}
              onValueSubmitted={() => {}}
              disabled={true}
              label={'created_at'}
            ></DateTimeInlineInputComponent>
            <TextInlineInputCompontent
              label="created_by"
              value={
                data?.created_by
                  ? `${data.created_by.firstName} ${data.created_by.lastName}`
                  : ''
              }
              disabled={true}
            />
          </FieldGroup>
          <FieldGroup>
            <DateTimeInlineInputComponent
              selectedTime={data?.updated_at}
              disabled={true}
              onValueSubmitted={() => {}}
              label={'updated_at'}
            />
            <TextInlineInputCompontent
              label="updated_by"
              value={
                data?.updated_by
                  ? `${data.updated_by.firstName} ${data.updated_by.lastName}`
                  : ''
              }
              disabled={true}
            />
          </FieldGroup>
        </InlineComponentsWrapper>
      </div>

      <div className="p-2">
        <ChecklistTemplateExpandableTable
          checklistTemplateId={checklistTemplateId}
        />
      </div>
    </PageRoot>
  )
}

export default ChecklistTemplateDetailPage
