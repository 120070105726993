import {
  IChecklistTemplate,
  IChecklistGroup,
  IChecklistItem,
  IPaginateResult,
} from 'src/service/OrgTypes'
import { request } from 'src/service/service-utils'
import { TableFilter } from 'src/ui-elements/Table/useTable'

export const getChecklistTemplatesTree = (projectId: number): Promise<any> => {
  return request(`/projects/${projectId}/checklist_templates_tree`, 'GET')
}

export const filteredChecklistTemplates = (
  projectId: number,
  filter: TableFilter,
  currentPage: number,
  itemsPerPage: number,
): Promise<IPaginateResult<IChecklistTemplate>> => {
  return request(
    `projects/${projectId}/checklist_templates_filtered/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const updateCheckListTemplate = (
  projectId: number,
  checklistTemplateId: number,
  checklistTemplate: Partial<IChecklistTemplate>,
): Promise<IChecklistTemplate> => {
  return request(
    `projects/${projectId}/checklist_templates/${checklistTemplateId}/`,
    'PUT',
    checklistTemplate,
  )
}

export const getCheckListTemplate = (
  projectId: number,
  checklistId: number,
): Promise<IChecklistTemplate> => {
  return request(
    `projects/${projectId}/checklist_templates/${checklistId}/`,
    'GET',
  )
}

export const createCheckListTemplate = (
  projectId: number,
  checklistTemplate: Partial<IChecklistTemplate>,
) => {
  return request(
    `/projects/${projectId}/checklist_templates`,
    'POST',
    checklistTemplate,
  )
}

export const deleteChecklistTemplate = (
  projectId: number,
  checklistTemplateId: number,
): Promise<boolean> => {
  return request(
    `/projects/${projectId}/checklist_templates/${checklistTemplateId}`,
    'Delete',
    {},
    true,
  )
}

export const createCheckListItem = (
  projectId: number,
  checklistItem: IChecklistItem,
): Promise<IChecklistItem> => {
  return request(
    `/projects/${projectId}/checklist_items`,
    'POST',
    checklistItem,
  )
}

export const updateCheckListItem = (
  checklistItemId: number,
  updatedItem: Partial<IChecklistItem>,
) => {
  return request(`checklist_items/${checklistItemId}/`, 'PUT', updatedItem)
}

export const deleteMultipleChecklistTemplates = (
  projectId: number,
  checklistTemplateIds: number[],
): Promise<number> => {
  return request(
    `/projects/${projectId}/checklist_templates/remove_multiple`,
    'POST',
    { checklist_template_ids: checklistTemplateIds },
    true,
  )
}

export const deleteChecklistItem = (
  checklistItemId: number,
): Promise<boolean> => {
  return request(`/checklist_items/${checklistItemId}`, 'Delete', {}, true)
}

export const deleteMultipleChecklistItems = (
  projectId: number,
  body: {
    checklist_item_ids: number[]
    checklist_id?: number
    checklist_template_id?: number
  },
): Promise<boolean> => {
  return request(
    `projects/${projectId}/checklist_items/remove_multiple`,
    'POST',
    body,
  )
}

export const updateChecklistItemOrder = (
  checklistItemId: string | number,
  sequence: number,
  checklistGroupId: number,
) => {
  return request(`checklist_items/${checklistItemId}/order`, 'PUT', {
    sequence,
    checklist_group_id: checklistGroupId,
  })
}

export const createCheckListGroup = (
  projectId: number,
  checklistGroup: Partial<IChecklistGroup>,
): Promise<IChecklistGroup> => {
  return request(
    `/projects/${projectId}/checklist_groups`,
    'POST',
    checklistGroup,
  )
}

export const updateCheckListGroup = (
  checklistGroupId: number,
  header: string,
  value: string,
): Promise<IChecklistGroup> => {
  return request(`checklist_groups/${checklistGroupId}/`, 'PUT', {
    [header]: value,
  })
}

export const deleteChecklistGroup = (
  checklistGroupId: number,
): Promise<boolean> => {
  return request(`/checklist_groups/${checklistGroupId}`, 'Delete', {}, true)
}

export const deleteMultipleChecklistGroups = (
  projectId: number,
  body: {
    checklist_group_ids: number[]
    checklist_id?: number
    checklist_template_id?: number
  },
): Promise<boolean> => {
  return request(
    `projects/${projectId}/checklist_groups/remove_multiple`,
    'POST',
    body,
  )
}

export const updateChecklistGroupOrder = (
  checklistGroupId: string | number,
  sequence: number,
) => {
  return request(`checklist_groups/${checklistGroupId}/order`, 'PUT', {
    sequence,
  })
}
